<template>
  <div class="container">
    <div class="topNav">
      <span @click="toPage(1)">首页</span>>
      <span @click="toPage(3)">企业诉求</span>>
      <span style="color: #1890ff">诉求详情</span>
    </div>
    <div class="content">
      <p>{{ detailObj.title }}</p>
      <div class="detailInfo">
        <p class="sub_title">详细信息</p>
        <ul>
          <li>
            诉求类型 <span>{{ detailObj.type }}</span>
          </li>
          <li>
            诉求标题 <span>{{ detailObj.title }}</span>
          </li>
          <li>
            诉求内容 <span>{{ detailObj.content }}</span>
          </li>
          <li>
            企业名称 <span>{{ detailObj.sqapplyer }}</span>
          </li>
          <li>
            提交时间 <span>{{ detailObj.createtime }}</span>
          </li>
        </ul>
      </div>
      <div class="progress">
        <p class="sub_title">进度及回复</p>
        <div style="height: 130px; margin-left: 130px; margin-top: 10px">
          <el-steps direction="vertical">
            <el-step v-for="(item, index) in progressData" :key="index">
              <template slot="title">
                <div class="stepNode">
                  <!-- <span>{{ item.nodename }}</span> -->
                  <span>处理人：{{ item.handlename }}</span>
                  <span>处理意见:{{ item.replycontent }}</span>
                  <div class="leftTime">{{ item.handletime }}</div>
                </div>
              </template>
              <template slot="icon"></template>
            </el-step>
          </el-steps>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      detailObj: {},
      tctime: "",
      progressData: [],
    };
  },

  created() {
    if (this.$route.params.object) {
      let temp = this.$route.params.object;
      sessionStorage.setItem("detailObj", temp);
    }
    let temp2 = sessionStorage.getItem("detailObj");
    this.detailObj = JSON.parse(temp2);
    this.getProgress();
  },
  mounted() {},
  methods: {
    toPage(index) {
      if(index==1){
        //sessionStorage.setItem("titleType",1,5*60*1000);
        this.$router.push({name:"Layout",params:{titleType:1}});
      }
      if(index==3){
        this.$router.push({ name: "qysq"});
      }
    },
    getProgress() {
      let that = this;
      let sheetId = that.detailObj.sheetId;
      let url = "/dev-api/business/handle/sq/publicInfo";
      let param = {
        sheetId: sheetId,
      };
      that.$httpApi
        .post(url, param)
        .then((res) => {
          that.progressData = res.data.sqPublicInfoLog;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang='scss' scoped>
$widthContent: 1200px;
$widthInfo: 1150px;
$fontColor: #333;
.container {
  background: #eff2f5;
  padding: 0 40px;
  padding-bottom: 50px;
  margin-bottom: -20px;
  min-height: 600px;
}
.topNav {
  width: $widthContent;
  height: 35px;
  line-height: 35px;
  margin: 0 auto;
  cursor: pointer;
}
.content {
  width: $widthContent;
  background: #fff;
  margin: 0 auto;
  padding: 24px;
  .detailInfo,
  .progress {
    width: $widthInfo;
    height: 273px;
    overflow-y: auto;
    margin: 20px auto;
    border: 1px solid rgb(232, 232, 232);
    border-radius: 8px;
    padding: 20px 0 0 20px;
    &::-webkit-scrollbar {
      display: none;
    }
    ul li {
      font-size: 14px;
      color: #999;
      margin: 15px 0;
      display: flex;
      span {
        color: $fontColor;
        margin-left: 30px;
        width: 1020px;
        display: inline-block;
        text-align: justify;
      }
    }
    .sub_title {
      font-size: 20px;
      color: $fontColor;
    }
    .stepNode {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      span {
        font-size: 14px !important;
        color: $fontColor;
      }
      .leftTime {
        width: 70px;
        text-align: left;
        position: absolute;
        left: -160px;
        font-size: 12px !important;
        color: #666;
      }
    }
  }
}
</style>
